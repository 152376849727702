





import { SpotRoleAggregationWidgetAdapterPropsMixin } from '@/features/core/components/spot-role-aggregation-widget-adapter/mixin';
import {
  SpotRoleAggregationCounterConfig,
  counterConfigCurrent,
  counterConfigLastYear,
  counterConfigLastMonth,
} from '@/features/core/components/spot-role-aggregation-widget-counter-control/model';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class OilfoxAggregationWidget extends Mixins(SpotRoleAggregationWidgetAdapterPropsMixin) {
  private get configs(): SpotRoleAggregationCounterConfig[] {
    return [
      counterConfigLastMonth('fillingLevelLiters'),
      counterConfigLastYear('fillingLevelLiters'),
      counterConfigCurrent('fillingLevelLiters'),
    ];
  }
}
